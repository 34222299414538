.headerStyle {
    text-align: center;
    width: 100%;
    height: 64px;
    padding-inline: 0px;
  }
  
  .headerBlock {
    padding-top: 20px;
    height: calc(100vh - (64px));
  }

.contentText{
    padding-top: 20px;
    width: fit-content;
    text-align: start;
    font-size: large;
}

.middleBlock{
    width: 80%;
    margin-top: 40px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

@media only screen and (max-width: 2000px) {
    .collapseBlock{
        margin-top: 20px;
    }
}