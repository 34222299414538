.headerStyle {
  text-align: center;
  width: 100%;
  padding-inline: 0px;
  height: 64px;
}

.contentBlock {
  height: calc(100vh - (64px));
  width: 100%;
  /* align-items: center; */
}

.middleBlock {
  padding-top: 60px;
  width: 800px;
  margin: auto;
}

.collapseBlock {
  width: 100%;
}

.collapseBlock {
  margin-top: 40px;
}

@media only screen and (max-width: 800px) {
  .middleBlock {
    width: 90%;
    margin: auto;
  }
}
