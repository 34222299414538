.headerStyle{
  text-align: center;
  width: 100%;
  height: 120;
}

.headerBlock {
  width: 100%;
  height: calc(100vh - (64px));
}

.content {
  margin-top: 50px;
  height: 400px;
}

.contentText {
  padding-top: 20px;
  width: fit-content;
  text-align: start;
  font-size: medium;
}

.collapseBlock {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 2000px) {
  .collapseBlock {
    margin-top: 20px;
  }
}
