.titleRender{
    display: flex;
    justify-content: space-between;
}

.buttonImage{
    margin-right: 5px;
}

.image{
    background-color:'#4085e3';
}

@import "/node_modules/flag-icons/css/flag-icons.min.css";
