.headerBlock {
  width: 100%;
  height: 90px;
}

.headerImage {
  height: 54px !important;
  width: 160px !important;
  margin-left: 40px;
  margin-top: 10px;
}

.googlePlayButton {
  width: 150px;
}

.headerName {
  text-decoration: none;
  background-color: white;
  color: black;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  left: 90px;
  top: 10px;
}

.headerAvatar {
  display: flex;
  flex-direction: row;
  margin-right: 40px;
  margin-top: 10px;
}

.sunIcon {
  color: #ffffff;
}

.sunIcon:hover {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

.moonIcon {
  color: #ffffff;
}

.moonIcon:hover {
  transition: transform 0.3s ease;
  transform: scale(1.1);
}

@media only screen and (max-width: 600px) {
  .headerDevider {
    line-height: 50px;
    font-size: large;
  }

  .headerImage {
    height: 30px !important;
    width: 90px !important;
  }
}
