.headerStyle {
    text-align: center;
    width: 100%;
    padding-inline: 0px;
    height: 64;
  }

.contentBlock{
    height: calc(100vh - (64px));
    display: flex;
    align-items: center;
}

.content{
    padding-top: 50px;
    height: 400px;
}

.collapseBlock{
    width: 600px;
    margin: auto;
}

.collapseBlock{
    margin-top: 40px;
}

@media only screen and (max-width: 600px) {
    .collapseBlock{
        margin-top: 50px !important;
        width: 330px;
        margin: auto;
    }
}