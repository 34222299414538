.headerStyle {
  text-align: center;
  width: 100%;
  padding-inline: 0px;
  height: 120;
}

.contentPage {
  height: calc(100vh - (64px));
}

.contentDevider {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.leftBlock {
  width: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.textLeftBlock {
  background: linear-gradient(
    to right,
    #c1d202,
    #c1d202,
    #c1d202,
    #00365f,
    #00365f,
    #00365f
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  padding-top: 20%;
  margin-left: 100px;
  height: fit-content;
  width: fit-content;
  font-weight: 700;
  font-size: 100px;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
}

.dotColor {
  -webkit-text-fill-color: #c1d202;
  color: #c1d202 !important;
}

.marginPlayButton {
  margin-top: 130px;
  margin-bottom: 30px;
}

.googlePlayButton {
  width: 150px;
}

.rightBlock {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.item {
  height: 250px;
  width: 250px;
  background-color: #c1d202;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 30px;
  font-weight: 600;
  cursor: pointer;
  font-family: 'Squad Semi Bold', Helvetica, sans-serif;
  color: white;
}

.item:hover {
  color: black;
}

.itemHover:hover {
  color: white;
}

@media only screen and (min-width: 1800px) {
  .leftBlock {
  }

  .textLeftBlock{
    font-size:  130px;
  }

  .contentItems {
    height: 100%;
  }

  .item {
    width: 15vw;
    height: 15vw;
    line-height: 10vw;
    font-size: 40px;
  }
}

@media only screen and (max-width: 600px) {
  .contentPage{
    width: 100%;
  }

  .leftBlock {
    width: 100%;
  }

  .contentDevider {
    text-align: center;
    display: flex;
    flex-direction: column;
    padding-top: 100px;
  }

  .marginPlayButton {
    margin-top: 10px;
  }

  .textLeftBlock {
    font-size: 60px;
    padding-left: 0px;
    margin: 0 auto;
    height: 300px;
    text-align: center;
  }

  .contentDevider {
    width: 100%;
    flex-direction: column;
  }

  .rightBlock {
    display: flex;
    padding-top: 50px;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-top: 50px;
  }

  .item {
    width: 80vw;
    height: 35vw;
    font-size: 30px;
  }
}
