.headerStyle {
  text-align: center;
  width: 100%;
}

.headerBlock {
  padding-top: 55px;
  height: calc(100vh - (64px));
}

.middleBlock {
  width: 800px;
  margin: auto;
}

.collapseBlock {
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .middleBlock {
    width: 90%;
    margin: auto;
  }
}
