.headerStyle {
    text-align: center;
    width: 100%;
    padding-inline: 0px;
    height: 64;
  }

  .contentBlock {
    height: calc(100vh - (64px));
  }
  
.content{
    margin-top: 50px;
    height: 400px;
    background-color: wheat;
}

.contentText{
    padding-top: 20px;
    width: fit-content;
    text-align: start;
    font-size: large;
}

.collapseBlock{
    width: 100%;
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
}

.formMiddler{
    width: 600px;
    margin-top: 40px;
    margin: auto;
}

.formMiddler{
    justify-content: center;
    align-items: center;
}

@media only screen and (max-width: 600px) {
    .collapseBlock{
        margin-top: 20px;
    }

    .formMiddler{
        width: 330px;
        margin-top: 40px;
        margin: auto;
    }
}