.headerStyle {
  text-align: center;
  width: 100%;
  padding-inline: 0px;
  height: 64;
}

.contentBlock {
  height: calc(100vh - (64px));
}

.middleBlock {
  display: flex;
  text-align: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  top: 100px;
}

.contentText {
  text-align: center;
}

.marginContact {
  margin-bottom: 20px;
}

.btnSubmit {
  display: flex;
  justify-content: center;
}

.formWidth {
  width: 600px;
  margin: auto;
  position: relative;
  left: -20px;
}

@media only screen and (max-width: 600px) {
  .formWidth {
    margin: 0px;
    width: 80%;
    margin: auto;
    position: relative;
    left: 0px;
  }

  .btnSubmit {
    align-items: center;
  }
}
