.headerBlock{
    background-color: white !important;
    width: 100%;
    background-color: aqua;
    height: 100%;
    align-items: center;
    justify-content: center;
    margin: auto;
}

.middleBlock{
    vertical-align: middle;
    text-align: center;
    justify-content: center;
    width: 100%;
    padding-right: 50px;
    margin: auto;
    margin: 0;
    position: absolute;
    top: 30%;
    transform: translateX(50%);
}