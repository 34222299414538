.headerStyle {
  text-align: center;
  width: 100%;
  padding-inline: 0px;
  height: 64;
}

.contentBlock {
  height: calc(100vh - (64px));
  width: 100%;
  padding-top: 30px;
}

.contentText {
  padding-top: 20px;
  width: fit-content;
  text-align: start;
  font-size: large;
}

.btnLogout {
  margin-top: 20px;
}

.collapseBlock {
  width: 100%;
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

@media only screen and (max-width: 2000px) {
  .collapseBlock {
    margin-top: 20px;
  }
}
